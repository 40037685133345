@font-face {
	font-family: 'Nunito-Bold';
	src: url('./fonts/Nunito-Bold.ttf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito';
	src: url('./fonts/Nunito-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

html {
	font-size: 100%;
	font-size: 12px;
	font-family: 'Nunito', sans-serif;
}

body {
	background: #221f1f;
	color: #ffffff;
	font-family: 'Nunito', sans-serif;
	width: 100%;
	height: 100%;
	line-height: 1;
	box-sizing: border-box;
	overflow: auto;
}

.Header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: min(45px, 6vh);
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	z-index: 6;
}

.Header .contents {
	position: relative;
	display: flex;
	width: 95%;
	height: 97%;
	background-color: #000000;
	padding: 0 2.5%;
	padding-top: min(13px, 0.5%);
	justify-content: left;
}
.Header .Date {
	color: #fff;
	padding: 0px 15px;
	margin: 5px 0px;
	border-radius: 25px;
	background-color: #191919;
	font-family: 'Nunito', sans-serif;
	font-weight: bold;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
}
.HeaderSeparator {
	position: fixed;
	top: calc(min(45px, 6vh) - 2px);
	left: 0;
	width: 100vw;
	height: min(45px, 3%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, black 1%, transparent 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, black 1%, transparent 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
	user-select: none;
	pointer-events: none;
	z-index: 5;
}

.Footer {
	position: relative;
	left: 0;
	bottom: 0;
	width: 100%;
	height: min(30px, 3vh);
	margin: 0;
	margin-top: 1em;
	background-color: black;
	color: white;
	text-align: center;
}

.Footer .Contents {
	position: relative;
	font-size: 0.75em;
}

.HeaderSpacer {
	position: relative;
	width: 100%;
	height: min(45px, 6vh);
}

.MainContent {
	width: 100%;
	/*height: 100vh;*/
	min-height: calc(100vh - min(45px, 6vh) - 2em);
	margin-top: 2em;
}

.MuiPaper-rounded {
	border-radius: 1em !important;
}

.MainContent .Content {
	flex: 2;
}

.MainContent .Login {
	position: relative;
	width: auto;
	height: auto;
	max-width: min(80%, 500px);
	max-height: min(90%, 400px);
}

.MainContent .Main {
	position: relative;
	width: 90%;
	max-width: 90%;
	min-height: 90%;
}

.MainGrid {
	min-height: 100%;
	padding: 1%;
	margin: 0;
}
.TopGrid {
	height: 55%;
	margin: 0;
	padding: 1%;
}
.BottomGrid {
	height: 45%;
	margin: 0;
	padding: 1%;
}

.Home {
	height: calc(100vh - min(45px, 6vh) - min(30px, 3vh) - 3em - 2px);
}

.InternalPage {
	min-height: calc(100vh - min(45px, 6vh) - min(30px, 3vh) - 3em - 2px) !important;
}

.Home .MainGrid {
	height: 90%;
}

.SectionTitle {
	height: 10%;
	margin: 0;
	padding: 0;
}
.SectionBody {
	height: 76%;
	margin: 0;
	padding: 0;
}
.SectionActions {
	/* height: 14%; */
	/* margin: 0; */
	/* padding: 0; */
	position: absolute;
	width: 98%;
	bottom: 0%;
	padding-bottom: 10px;
}

.Card {
	background-color: #cccccc;
	list-style: none;
	border-radius: 0.75em;
}
.Card .MuiCardHeader-root {
	padding: 0.5em;
}
.Card .MuiGridListTile-tile {
	border-radius: 0.75em;
}
.Card .MuiGridListTileBar-root {
	background: rgba(0, 0, 0, 0.8);
	padding: 5px 0px;
}
.Card .MuiGridListTileBar-title {
	user-select: none;
}

.Card .CardImage,
.SectionTitle .CardImage {
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	animation: zoomOut110 0.5s forwards;
}

.Card .CardSelector {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	background-color: rgba(255, 255, 255, 0.5);
	animation: fadeOutOpacity 0.5s forwards;
}

.Card .LargeIcon {
	color: #fff;
	border-radius: 50%;
	font-size: 2.5rem !important;
}

.SectionTitle .Title {
	list-style: none;
	height: 8vh;
}
.SectionTitle .Title .MuiGridListTile-tile {
	border-top-left-radius: 0.75em;
	border-top-right-radius: 0.75em;
}
.SectionTitle .Title .MuiGridListTileBar-root,
.SectionBody .Body .MuiGridListTileBar-root,
.SectionActions .Actions .MuiGridListTileBar-root {
	height: 100%;
}
.SectionTitle .Title .MuiGridListTileBar-title {
	font-size: 1.3em;
	font-family: 'Nunito-Bold', 'Nunito';
}

.SectionBody .Body {
	list-style: none;
	width: 100%;
}

.SectionBody .Body .BodyTitle {
	width: 98%;
	height: 10%;
	margin-left: 1%;
	margin-bottom: 1%;
	align-items: center;
}

.SectionBody .Body .DatePicker .MuiFormHelperText-root.Mui-error {
	display: none;
}

.SectionBody .Body .TableHeader {
	height: 10%;
	overflow: hidden;
	background-color: #221f1f;
}
.SectionBody .Body .TableHeader .TableHeaderCell {
	background-color: #221f1f;
	color: white;
	font-size: 1.25em;
}

.SectionBody .Body .TableWrapper {
	width: 98%;
	min-height: 77%;
	margin-left: 1%;
	border-radius: 0.5em !important;
	overflow: hidden;
}
.SectionBody .Body .SmallTableWrapper {
	width: 60%;
	min-height: 77%;
	margin: auto;
	border-radius: 0.5em !important;
	overflow: hidden;
}
.SectionBody .Body .TableContainer {
	width: 100%;
	height: 100%;
}
.SectionBody .Body .TableContainer .MuiTableCell-root {
	padding: 0.3em !important;
	font-family: 'Nunito-Regular', 'Nunito';
}

.SectionBody .Body .TableContainer .TableRowOdd {
	background-color: #f3f3f3;
}
.SectionBody .Body .TableContainer .TableRowEven {
	background-color: white;
}

.SectionBody .Body .TableContainer .TableRowOdd:hover,
.SectionBody .Body .TableContainer .TableRowEven:hover {
	background-color: #c1cfbb;
	-webkit-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.5);
}

.SectionBody .Body .TableContainer .TableRowOdd th,
.SectionBody .Body .TableContainer .TableRowOdd td,
.SectionBody .Body .TableContainer .TableRowEven th,
.SectionBody .Body .TableContainer .TableRowEven td {
	font-size: 1.25em;
}

.SectionBody .Body .Pagination {
	height: 10%;
}
.Pagination .MuiPagination-ul {
	justify-content: center;
	height: 100%;
}
.SectionBody .Body .Pagination .MuiTablePagination-toolbar {
	min-height: auto;
}

.SectionActions .Actions {
	list-style: none;
	text-align: right;
	padding: 0.2em 0;
}
.SectionActions .MuiPaper-rounded {
	border-radius: 0 !important;
	border-bottom-left-radius: 0.75em !important;
	border-bottom-right-radius: 0.75em !important;
}

.linear-progress-warning {
	background-color: #221f1f !important;
	width: '100%' !important;
}

.linear-progress-warning .MuiLinearProgress-barColorPrimary {
	background-color: #453f3f;
}

.MuiTypography-h5,
.MuiTypography-h6,
.MuiFormLabel-root,
.MuiInputBase-input,
.MuiGridListTileBar-titleWrap {
	font-family: 'Nunito-Regular', 'Nunito' !important;
}

.MuiTypography-h1,
.MuiTypography-h2 {
	font-family: 'Nunito', 'Nunito-Regular' !important;
}

.boldText{
	font-weight: bold;
}

@media screen and (hover: hover) {
	.Card:hover .CardSelector {
		animation: fadeInOpacity 0.5s forwards;
	}

	.Card:hover .CardImage {
		animation: zoomIn110 0.5s forwards;
	}
}

/* Main Boxes layout */
@media screen and (orientation: portrait) {
	.TopGrid {
		height: 23%;
		margin: 0 0 1% 0;
		padding: 1%;
		flex-grow: 0 !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.BottomGrid {
		height: 15%;
		margin: 0;
		padding: 1%;
		flex-grow: 0 !important;
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
}

@media (hover: none), (hover: on-demand) {
	.Header {
		height: calc(min(100vw, 100vh) * 0.1);
	}
	.HeaderSeparator {
		top: calc((min(100vw, 100vh) * 0.1) - 2px);
	}

	.HeaderSpacer {
		height: calc(min(100vw, 100vh) * 0.1);
	}

	.MainContent {
		min-height: calc(100vh - calc(min(100vw, 100vh) * 0.1) - 2em);
	}

	.MainContent .Footer {
		height: calc(min(100vw, 100vh) * 0.05);
	}

	.Home {
		height: calc(
			100vh - calc(min(100vw, 100vh) * 0.1) - calc(min(100vw, 100vh) * 0.05) - 3em - 2px
		);
	}

	.InternalPage {
		min-height: calc(
			100vh - calc(min(100vw, 100vh) * 0.1) - calc(min(100vw, 100vh) * 0.05) - 3em - 2px
		) !important;
	}
}

.Logo {
	width: 44%;
	height: 95%;
	margin: 0;
	padding: 0;
	margin-right: 2%;
	-webkit-transition: margin 0.125s ease;
	transition: margin 0.125s ease;
}

.Logo .Internal {
	height: 90%;
	position: relative;
}

.Logo .Internal IMG {
	max-width: 100%;
	max-height: 100%;
}

.UserProfile {
	position: absolute;
	width: 30%;
	height: 100%;
	left: 70%;
	text-align: right;
}
.UserProfile .User {
	float: right;
}
.UserProfile .User .UserDetails {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
	height: 100%;
}

.UserProfile .User .UserAvatar {
	flex: auto;
	align-self: center;
	justify-self: center;
	height: 100%;
}

.UserProfile .User .UserName {
	flex: auto;
	align-self: center;
	justify-self: center;
	margin: 4px;
	font-size: 18px;
	font-weight: 300;
	height: 100%;
}

.UserProfile .User .Menu {
	flex: auto;
	align-self: center;
	justify-self: center;
	padding: 0 5px;
	height: 100%;
	cursor: pointer;
}

.UserProfile .Login {
	position: relative;
	width: 100%;
	text-align: right;
}

.MenuFloating .MuiDrawer-paper {
	width: 20vw;
	min-width: 200px;
	background-color: black !important;
	color: white !important;
}

@media screen and (max-width: 600px) {
	.MenuFloating .MuiDrawer-paper {
		width: 100vw;
		min-width: 100%;
	}
}

.MenuFloating .Balance {
	text-align: center;
	padding: 0;
	font-size: 1em;
}

.MenuFloating .Balance .BalanceTitle{
	font-size: 0.4em;
	color: #D0366A;
}

.MenuFloating .Balance .BalanceName span{
	font-size: 1.25em;
}

.MenuFloating .MenuClose {
	position: absolute;
	top: 1vw;
	right: 1vw;
	z-index: 5;
	cursor: pointer;
}

.MenuFloating .MenuClose .closeIcon {
	width: 2.5vw;
	height: 2.5vw;
	min-width: 30px;
	min-height: 30px;
	fill: rgba(255, 255, 255, 0.8);
}

.MenuFloating .UserAvatar {
	flex: auto;
	width: 100%;
	align-self: center;
	justify-self: center;
	margin: 0;
	margin-top: 1.5vw;
}

.MenuFloating .UserAvatar .AvatarPic {
	width: 10vw;
	height: 10vw;
	min-width: 100px;
	min-height: 100px;
	margin: 1vw 0;
	left: 50%;
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	align-self: 'center';
}

.MenuFloating .UserAvatar .UserName {
	width: 80%;
	margin: 0 10% 0.1em 10%;
	text-align: center;
	font-family: 'Nunito', sans-serif;
	font-weight: 800;
	font-size: 1.8em;
}

.MenuFloating .UserAvatar .Email {
	width: 100%;
	margin: 0 2% 1em 2%;
	text-align: center;
	font-family: 'Nunito', sans-serif;
	font-weight: normal;
	font-size: 0.8em;
	word-break: break-all;
}

.MenuFloating .MuiListItem-button:hover,
.MenuFloating .MuiListItem-button:active {
	background-color: rgba(200, 200, 200, 0.05) !important;
}

.MenuFloating .Divider {
	background-color: rgba(255, 255, 255, 0.2);
}

.MenuFloating .MuiListItemAvatar-root {
	min-width: 30px !important;
	color: rgba(255, 255, 255, 0.6);
}

.Button {
	position: relative;
	display: inline-block;
	min-width: 8em !important;
	padding: 0-0.5em !important;
	border-radius: 2em !important;
	vertical-align: middle;
	margin: 0 1em !important;
	text-rendering: optimizeLegibility;
	box-sizing: border-box;
	text-transform: uppercase;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	font-family: 'Nunito-Bold', 'Nunito-Regular';
	-webkit-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.25);
}

.Button:hover {
	transform: scaleX(1.05) scaleY(1.05);
	transition: 500ms all;
}

.MuiButton-root.Mui-disabled {
	background-color: rgba(0, 0, 0, 0.35) !important;
	color: #666666 !important;
}

.ButtonHigh {
	font-size: 0.95rem !important;
	padding: 8px !important;
}

.ButtonSmall {
	font-size: 0.6rem !important;
	padding: 4px 12px !important;
}

.Grid {
	display: flex;
	flex-wrap: wrap;
	padding: 2em 4%;
}

.Grid .Item {
	position: relative;
	flex: auto;
	width: 15%;
	max-width: 20%;
	margin: 4px;
}

.Grid .Item .Content {
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 20px;
	border: 2px solid rgba(245, 245, 241, 0.1);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	overflow: hidden;
}

.Grid .Item .Image {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
}

.Grid .Item .Image IMG {
	vertical-align: middle;
	width: 100%;
}

.Grid .Item .ImageAnimation {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	animation: reduce150 0.3s forwards;
}

.Grid .Item .ImageAnimation IMG {
	vertical-align: middle;
	width: 100%;
}

.Grid .Item .Overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: transparent;
	animation: fadeToNormal 0.3s forwards;
}

.Grid .Item .Actions {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	font-weight: 600;
	line-height: 1.4;
}

.Grid .Item .Actions .CenteredY {
	position: relative;
	width: 100%;
	top: 50%;
	left: 0;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	text-align: center;
}

.Grid .Item .Actions .Button {
	padding: 0 0.5em;
	font-size: 1em;
	white-space: nowrap;
}

.Grid .Item .ItemTitle {
	position: relative;
	top: 2.5%;
	left: 2.5%;
	width: 95%;
	font-size: 2em;
	font-weight: 600;
	line-height: 1.4;
	margin-bottom: 0.25em;
	overflow-wrap: break-word;
}

.Grid .Item .ItemDescription {
	position: relative;
	top: 2.5%;
	left: 2.5%;
	width: 95%;
	font-size: 1.2em;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 1em;
	overflow-wrap: break-word;
}

.Grid .Item:hover .Overlay {
	background-color: black;
	animation: fadeToBlack 0.3s forwards;
}

.Grid .Item:hover .ImageAnimation {
	animation: enlarge150 0.3s forwards;
}

.Grid .Item:hover .Actions {
	animation: fadeInZoom 0.3s forwards;
}

.Grid .Item:hover .Actions .Button {
	font-size: 1.2em;
	margin: 0.5em 0;
}

@keyframes fadeToBlack {
	from {
		background-color: transparent;
	}
	to {
		background-color: rgba(0, 0, 0, 0.65);
	}
}

@keyframes fadeToNormal {
	from {
		background-color: rgba(0, 0, 0, 0.65);
	}
	to {
		background-color: transparent;
	}
}

@keyframes enlarge150 {
	from {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	to {
		width: 125%;
		height: 125%;
		top: -12.5%;
		left: -12.5%;
	}
}

@keyframes reduce150 {
	from {
		width: 125%;
		height: 125%;
		top: -12.5%;
		left: -12.5%;
	}
	to {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}

@keyframes fadeInZoom {
	from {
		width: 200%;
		height: 200%;
		top: -50%;
		left: -50%;
		font-size: 1.5em;
		opacity: 1;
	}
	to {
		width: 100%;
		height: 100%;
		top: 0%;
		left: 0%;
		font-size: 0.8em;
		opacity: 1;
	}
}

@keyframes fadeOutZoom {
	from {
		width: 100%;
		height: 100%;
		top: 0%;
		left: 0%;
		opacity: 1;
	}
	to {
		width: 150%;
		height: 150%;
		top: -25%;
		left: -25%;
		opacity: 0;
	}
}

.Card * {
	font-size: 100% !important;
}
.MuiCardActions-root {
	justify-content: flex-end;
}

@media screen and (min-width: 1200px) {
	html {
		font-size: 100%;
	}
	.Card {
		font-size: 1em;
	}
	.Grid .Item {
		width: 16%;
		max-width: 20%;
	}
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
	html {
		font-size: 90%;
	}
	.Card {
		font-size: 1.1em;
	}
	.Grid .Item {
		width: 16%;
		max-width: 20%;
	}
}

@media screen and (min-width: 800px) and (max-width: 999px) {
	html {
		font-size: 80%;
	}
	.Card {
		font-size: 1.25em;
	}
	.Grid .Item {
		width: 20%;
		max-width: 25%;
	}
}

@media screen and (min-width: 600px) and (max-width: 799px) {
	html {
		font-size: 70%;
	}
	.Card {
		font-size: 1.4em;
	}
	.Grid .Item {
		width: 20%;
		max-width: 25%;
	}
}

@media screen and (min-width: 480px) and (max-width: 599px) {
	html {
		font-size: 60%;
	}
	.Card {
		font-size: 1.6em;
	}
	.Grid .Item {
		width: 30%;
		max-width: 33%;
	}
}

@media screen and (min-width: 320px) and (max-width: 479px) {
	html {
		font-size: 50%;
	}
	.Card {
		font-size: 2em;
	}
	.Grid .Item {
		width: 45%;
		max-width: 50%;
	}
}

@media screen and (min-width: 240px) and (max-width: 319px) {
	html {
		font-size: 40%;
	}
	.Card {
		font-size: 2.25em;
	}
	.Grid .Item {
		width: 45%;
		max-width: 50%;
	}
}

@media screen and (min-width: 1px) and (max-width: 239px) {
	html {
		font-size: 35%;
	}
	.Card {
		font-size: 2.8em;
	}
	.Grid .Item {
		width: 50%;
		max-width: 100%;
	}
}
